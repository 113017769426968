import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateUnreadCount } from "../../store/actions"; // Import your action to update the unread count
import { request } from "../../services/networking/request";
import { API_CONSTANT_MAP } from "../../utils/constants/endpoints"; // Use your custom request function

const PollingComponent = (props) => {
  useEffect(() => {
    // Function to fetch unread counts from the API
    const fetchUnreadCount = async () => {
      try {
        const endpoint = API_CONSTANT_MAP.get_unread_count;

        // Using your custom request function
        const response = await request("POST", endpoint);

        console.log(response.data.unread_count);
        // Extract the unread count from the response
        const unreadCounts = response.data.unread_count;

        props.updateUnreadCount({
          payload: unreadCounts,
        });
      } catch (error) {
        console.error("Failed to fetch unread messages:", error);
      }
    };

    // Polling every 2 minutes
    const interval = setInterval(fetchUnreadCount, 120000);

    // Fetch unread count immediately on mount
    fetchUnreadCount();

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [updateUnreadCount]);

  return null; // Since this is a background polling component, no UI is rendered
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateUnreadCount }, dispatch);

// // Pass `null` for `mapStateToProps` since you are not using state in this component
// const mapDispatchToProps = (dispatch) => ({
//   updateUnreadCount: (count) => dispatch(updateUnreadCount(count)), // Dispatch to update unread count
// });

export default connect(null, mapDispatchToProps)(PollingComponent);
