import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../components/layout/NavBar";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import Loader from "../components/Loading";
import Modal from "../components/Modal";
import styled from "styled-components";
// import Button from '@mui/material/Button';
import { daysBetween, getMonthYear } from "../utils/helpers/date";
import { device } from "../utils/constants/css";
import RoundedImage from "../components/RoundedImage";
import unknown_gw from "../assets/unknown_gw.png";
import { BsFillHeartFill } from "react-icons/bs";
import OuterContainer from "../components/layout/OuterContainer";
import { ImageSlider, SkeletonImageSlider } from "../components/ImageSlider";
import Button from "../components/common/Button";
import { size } from "../utils/constants/css";
import { default_title, default_description } from "../utils/constants/meta";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Meta from "../components/Meta";
import { RiArrowGoBackFill } from "react-icons/ri";
import { FaExchangeAlt } from "react-icons/fa";
import { CgArrowsExchange } from "react-icons/cg";

const Details = styled.div`
  // margin-bottom: 20px;
  white-space: pre-wrap;

  & > div > .overview_section_title {
    font-size: 14px;
    color: #6b6b6b;
    font-weight: 700;
    font-weight: bold;
    outline-color rgb(34, 34, 34);
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2); /* Subtle shadow for boldness */
  }

  & > div > .overview_section_value {
    font-size: 1rem;
    color: #000;
    font-weight: 500;
    outline-color rgb(34, 34, 34);
  }
`;

const SkeletonButton = styled(Skeleton)`
  /* Inherit styles from ExistingComponent */
  ${Button}
`;

const SubTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
  color: #2c2339;
  margin: 30px 0px;
  padding: 0px;
`;

const Section = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  margin: 0px;
  padding: 0px;
  flex: 0 0 90px;
`;

const DetailsDescription = styled.p`
  margin: 15px 0px 0px 0px;
  font-size: 1rem;
  line-height: 2;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;

const SaveButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
  opacity: 0.9;
  box-shadow: 0 4px 20px rgb(34 34 34 / 0.3);
`;

const BackButton = styled.button`
  display: none;

  @media screen and (max-width: ${size.mobileL}px) {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #fff;
    opacity: 0.9;
    box-shadow: 0 4px 20px rgb(34 34 34 / 0.3);
  }
`;

const SellerDetails = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;

  & > .seller_information_container {
    display: flex;
    column-gap: 10px;
    // padding: 10px 0px;

    & > .seller_information > * {
      margin: 0px;
      padding: 0px;
      display: block;
    }
  }
`;

const SellerName = styled.p`
  color: #444;
  font-size: 1rem;
  font-weight: 600;
`;

const ListingContainer = styled.div`
  display: flex;
  padding: 0px;
  margin: 0px;
  flex-flow: row wrap;
  flex-direction: row;
  align-content: flex-start;
  justify-content: center;
  width: 100%;

  @media ${device.mobileS} {
    flex-direction: column;
    #imageContainer {
      width: 100%;
      height: 450px;
    }
    #detailContainer {
      width: 100%;
    }
  }
  @media ${device.laptop} {
    flex-direction: row;
    column-gap: 60px;
    #imageContainer {
      height: 700px;
      width: 50%;
    }
    #detailContainer {
      width: 36%;
    }
  }
`;

const ListingDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px;
  align-content: flex-start;
  row-gap: 10px;

  max-height: calc(100vh - 100px);
  overflow-y: scroll;

  @media screen and (max-width: ${size.tablet}px) {
    max-height: unset;
    overflow: unset;
    padding: 20px 0px;
  }
`;

const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
  color: #000; /* Darker text */
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2); /* Subtle shadow for boldness */
`;

const ModalText = styled.textarea`
  padding: 5px;
  border: 1px solid #afafaf;
  border-radius: 5px;
  resize: none;

  &:focus {
    outline: none !important;
    border: 1px solid #00a87e;
  }
`;

const SwapList = styled.div`
  overflow-y: auto;
  margin: 10px 0px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items vertically in the center */
  margin: 20px 0px 20px 0px; /* Add appropriate margins */
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.24px;
  color: #2c2339;
  margin: 0; /* Remove extra margin */

  @media screen and (max-width: ${size.tablet}px) {
    font-size: 20px;
    margin: 0;
  }
`;

const FreeTag = styled.span`
  background-color: #00a87e;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  // margin-left: 10px; /* Adds space between title and tag */
`;
const ListingDetailNavBar = styled(NavBar)`
  @media screen and (max-width: ${size.tablet}px) {
    display: none;
  }
`;

const ListingDetailOuterContainer = styled(OuterContainer)`
  @media screen and (max-width: ${size.mobileL}px) {
    margin-top: 0px;
  }
`;

const ListingDetails = (props) => {
  const navigate = useNavigate();
  const [chatOpen, setChatOpen] = useState(false);
  const [swapOpen, setSwapOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [conversationId, setConversationId] = useState(false);
  const { id } = useParams();
  const [listing, setListing] = useState({});
  const [buyerListings, setBuyerListings] = useState([]);
  const [user, setUser] = useState({});
  const [listingImages, setListingImages] = useState([]);
  const [swapSelect, setSwapSelect] = useState({});
  const [checkedState, setCheckedState] = useState();

  console.log(listing);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    // get listing data
    let endpoint = API_CONSTANT_MAP.get_listing_by_id;
    let result = request("POST", endpoint, { id: id });

    result
      .then((result) => {
        if (result.data.success) {
          setListing(result.data.listing);
          setUser(result.data.user);
          setListingImages(result.data.listing_images);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });

    if (props.state.user && props.state.user.token) {
      // get listing favourite data

      let favourite_endpoint = API_CONSTANT_MAP.get_favourite_listing;
      let fav_result = request("POST", favourite_endpoint, { listing_id: id });
      fav_result
        .then((fav_result) => {
          if (fav_result.data.success) {
            let result = fav_result.data;
            setIsSaved(result.data.isfavourite);
          }
        })
        .catch(() => {});

      // get listing conversation

      let listing_conversation_endpoint =
        API_CONSTANT_MAP.get_listing_conversation;
      let listing_conversation = request(
        "POST",
        listing_conversation_endpoint,
        { listing_id: id }
      );
      listing_conversation
        .then((listing_conversation) => {
          if (listing_conversation.data.success) {
            let result = listing_conversation.data;
            setConversationId(result.data.conversation_id);
          }
        })
        .catch(() => {
          alert("something wrong with pulling conversation id");
        });

      // get user swaps

      const endpoint = API_CONSTANT_MAP.get_user_listings;
      const result = request("POST", endpoint, { data: null });
      result
        .then((result) => {
          if (result.data.success) {
            setBuyerListings(result.data.buyer_listings);
          }
        })
        .catch(() => {});
    }
  }, []);

  const handleChatMessage = (e) => {
    e.preventDefault();
    const form = e.target;
    const chat_message = form.elements["chatmessage"].value;

    setIsLoading(true);
    const endpoint = API_CONSTANT_MAP.create_conversation;
    const result = request("POST", endpoint, {
      seller_id: user.id,
      listing_id: id,
      message: chat_message,
    });
    result
      .then((result) => {
        if (result.data.success) {
          navigate(`/inbox/message/${result.data.id}`);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    setChatOpen(!chatOpen);
  };

  const handleChatOpen = (e) => {
    e.preventDefault();
    if (
      JSON.stringify(props.state.user) === "{}" ||
      props.state.user === undefined
    ) {
      navigate("/signin");
    } else if (conversationId) {
      navigate(`/inbox/message/${conversationId}`);
    } else {
      setChatOpen(!chatOpen);
    }
  };

  const handleChatModal = (e) => {
    e.preventDefault();
    setChatOpen(!chatOpen);
  };

  const handleSwap = (e) => {
    e.preventDefault();
    if (
      JSON.stringify(props.state.user) === "{}" ||
      props.state.user === undefined
    ) {
      navigate("/signin");
    } else if (conversationId) {
      navigate(`/inbox/message/${conversationId}`);
    } else {
      setSwapOpen(true);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  const handleFavourite = () => {
    if (
      JSON.stringify(props.state.user) === "{}" ||
      props.state.user === undefined
    ) {
      navigate("/signin");
    } else {
      setIsSaved(!isSaved);
      const endpoint = API_CONSTANT_MAP.create_favourite_listing;
      const result = request("POST", endpoint, { listing_id: id });
      result
        .then((result) => {
          let data = result.data;
          if ("error" in data) {
            setIsSaved(!isSaved);
          }
        })
        .catch(() => {
          setIsSaved(!isSaved);
        });
    }
  };

  useEffect(() => {
    var arr = new Array(buyerListings.length).fill(false);
    setCheckedState(arr);
  }, [buyerListings]);

  const handleSwapSelection = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    const selectedItems = buyerListings.filter(
      (item, index) => updatedCheckedState[index]
    );
    const selectedItemIds = selectedItems.map((item) => parseInt(item["id"]));
    setSwapSelect(selectedItemIds);
  };

  const handleSwapModal = (e) => {
    e.preventDefault();

    setSwapOpen(!swapOpen);
  };

  const submitSwap = () => {
    setIsLoading(true);

    const endpoint = API_CONSTANT_MAP.create_conversation_swap;
    const result = request("POST", endpoint, {
      seller_id: user.id,
      listing_id: id,
      swaps: swapSelect,
    });
    result
      .then((result) => {
        if (result.data.success) {
          navigate("/inbox/message/" + result.data.id);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    setChatOpen(!chatOpen);
  };

  const handleReceiveGiveaway = (e) => {
    e.preventDefault();
    if (props.state.user === undefined) {
      navigate("/signin");
    } else {
      // Handle receiving the giveaway
    }
  };

  return (
    <Meta
      pageMeta={{
        title: listing?.title ? `${listing.title} - Swapthings` : default_title,
        description: listing?.description
          ? `${listing.description} - Swapthings`
          : default_description,
      }}
    >
      <ListingDetailNavBar user={props.state.user} />
      <ListingDetailOuterContainer>
        <ListingContainer>
          <div id="imageContainer" style={{ position: "relative" }}>
            {listingImages.length > 0 ? (
              <ImageSlider slides={listingImages} />
            ) : (
              <SkeletonImageSlider />
            )}
            <SaveButton variant="contained" onClick={handleFavourite}>
              {isSaved ? (
                <BsFillHeartFill color="#A61A2E" />
              ) : (
                <BsFillHeartFill color="#666" />
              )}
            </SaveButton>
            <BackButton onClick={goBack}>
              <RiArrowGoBackFill size={15} />
            </BackButton>
          </div>

          <ListingDetailContainer id="detailContainer">
            <p style={{ fontSize: "14px", margin: "0px" }}>
              {listing?.created_at ? (
                `Posted ${daysBetween(listing.created_at)} in ${
                  listing.location
                }`
              ) : (
                <Skeleton />
              )}
            </p>

            <TitleContainer>
              <Title>{listing.title}</Title>
              {listing.is_available_for_giveaway && <FreeTag>Free</FreeTag>}
            </TitleContainer>

            {Object.keys(listing).length > 0 ? (
              <ButtonContainer>
                {/* Only show Message Seller button if it's a giveaway */}
                {listing.is_available_for_giveaway ? (
                  <Button
                    backgroundColor="#fff"
                    onClick={handleChatOpen}
                    radius="5px"
                    color="#00a87e"
                    border="1.5px solid #00a87e"
                    style={{ fontWeight: "bold", padding: "8px 10px" }}
                  >
                    Message Seller
                  </Button>
                ) : (
                  <>
                    {/* Swap Option */}
                    {listing.is_available_for_swap && (
                      <Button
                        onClick={handleSwap}
                        radius="5px"
                        color="#fff"
                        backgroundColor="#00a87e"
                        border="none"
                        style={{ fontWeight: "bold", padding: "9px 10px" }}
                      >
                        Swap your book
                      </Button>
                    )}

                    {/* Sale Option */}
                    {listing.is_available_for_sale && listing.price && (
                      <Button
                        onClick={handleChatOpen}
                        radius="5px"
                        color="#fff"
                        backgroundColor="#00a87e"
                        border="none"
                        style={{ fontWeight: "bold", padding: "9px 10px" }}
                      >
                        Buy book for ${listing.price}
                      </Button>
                    )}

                    <Button
                      backgroundColor="#fff"
                      onClick={handleChatOpen}
                      radius="5px"
                      color="#00a87e"
                      border="1.5px solid #00a87e"
                      style={{ fontWeight: "bold", padding: "8px 10px" }}
                    >
                      Message Seller
                    </Button>
                  </>
                )}
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <Skeleton width={100} height={40} />
                <Skeleton width={100} height={40} />
              </ButtonContainer>
            )}

            {/* {Object.keys(listing).length > 0 ? (
              <ButtonContainer>
                <Button
                  onClick={handleSwap}
                  radius="5px"
                  color="#fff"
                  backgroundColor="#00a87e"
                  border="none"
                  style={{ fontWeight: "bold", padding: "9px 10px" }}
                >
                  Swap your item
                </Button>
                <Button
                  backgroundColor="#fff"
                  onClick={handleChatOpen}
                  radius="5px"
                  color="#00a87e"
                  border="1.5px solid #00a87e"
                  style={{ fontWeight: "bold", padding: "8px 10px" }}
                >
                  Message Seller
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer>
                <Skeleton width={100} height={40} />
                <Skeleton width={100} height={40} />
              </ButtonContainer>
            )} */}

            <Details>
              {Object.keys(listing).length > 0 && <SubTitle>Details</SubTitle>}

              {listing.item_condition && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      margin: "5px 0px",
                    }}
                  >
                    <Section>Condition</Section>
                    <span class="overview_section_value">
                      {listing.item_condition}
                    </span>
                  </div>
                </>
              )}

              {listing?.category && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      margin: "5px 0px",
                    }}
                  >
                    <Section>Category</Section>
                    <span class="overview_section_value">
                      {listing.category}
                      {" - "}
                      {listing.subcategory}
                    </span>
                  </div>
                </>
              )}
              {listing.description && <SubTitle>Description</SubTitle>}
              <DetailsDescription>
                {Object.keys(listing).length > 0 ? (
                  listing.description
                ) : (
                  <Skeleton height={50} />
                )}
              </DetailsDescription>
            </Details>

            <hr
              style={{
                background: "#a2a2a2",
                // border: "none",
                height: "2px",
                margin: "0px",
              }}
            />

            <SellerDetails>
              {Object.keys(listing).length > 0 && (
                <SubTitle>Meet the seller</SubTitle>
              )}

              <div class="seller_information_container">
                <RoundedImage
                  imageHeight={70}
                  imageWidth={70}
                  borderRadiusPct={50}
                  image={user?.avatar ? user.avatar : unknown_gw}
                />

                <div class="seller_information">
                  <SellerName>
                    {user.first_name || <Skeleton width={100} />}{" "}
                    {user.last_name}
                  </SellerName>
                  <p>
                    {listing?.created_at ? (
                      `Member since ${getMonthYear(user.created_at)}`
                    ) : (
                      <Skeleton width={100} />
                    )}
                  </p>
                </div>
              </div>
            </SellerDetails>
          </ListingDetailContainer>

          <Modal isOpen={chatOpen} onClose={handleChatModal}>
            <form onSubmit={handleChatMessage}>
              <ModalBox>
                <ModalTitle>Send a Message</ModalTitle>
                <ModalText
                  rows="4"
                  style={{ display: "block", marginBottom: "10px" }}
                  name="chatmessage"
                />
                <Button
                  type="submit"
                  style={{ backgroundColor: "#00a87e", border: "none" }}
                >
                  Message
                </Button>
              </ModalBox>
            </form>
          </Modal>

          <Modal isOpen={swapOpen} onClose={handleSwapModal}>
            {buyerListings.length === 0 ? (
              <ModalTitle>You don't have books to Swap</ModalTitle>
            ) : (
              <ModalTitle>Select the books you would like to Swap</ModalTitle>
            )}
            <Button
              type="submit"
              onClick={submitSwap}
              style={{ backgroundColor: "#00a87e", border: "none" }}
              disabled={
                buyerListings.length === 0 ||
                Object.keys(swapSelect).length === 0
              }
            >
              Swap
            </Button>
            <SwapList>
              {Object.keys(buyerListings).length !== 0 &&
                buyerListings.map((item, index) => (
                  <div
                    key={`multiselect-checkbox-${item.id}`}
                    style={{
                      border: "1px solid #c0c0c0",
                      padding: "5px",
                      margin: "10px 0px",
                    }}
                  >
                    <input
                      key={`multiselect-input-${item.id}`}
                      type="checkbox"
                      value={item.id || ""}
                      checked={checkedState[index]}
                      onChange={() => handleSwapSelection(index)}
                    />
                    <span style={{ marginLeft: 5 }}>{item.title}</span>
                  </div>
                ))}
            </SwapList>
          </Modal>
        </ListingContainer>
      </ListingDetailOuterContainer>
    </Meta>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(ListingDetails);
