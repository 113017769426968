const initialState = {
  isLoading: false,
  isError: false,
  first_name: "",
  last_name: "",
  avatar: "",
  token: null,
  user_id: null,
  unread_message_count: 0,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGNUP_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        first_name: action.payload.user.first_name,
        last_name: action.payload.user.last_name,
        avatar: action.payload.user.avatar,
        token: action.payload.token,
        user_id: action.payload.user.id,
        unread_message_count: 0, // Initial unread count on signup
      };
    case "SIGNUP_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case "SIGNIN_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "SIGNIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        first_name: action.payload.user.first_name,
        last_name: action.payload.user.last_name,
        avatar: action.payload.user.avatar,
        token: action.payload.token,
        user_id: action.payload.user.id,
        unread_message_count: action.payload.user.unread_message_count, // Set unread count from backend
      };
    case "SIGNIN_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case "SIGNOUT":
      return {
        ...initialState, // Reset state to initial values but preserve the structure
      };

    case "UPDATE_UNREAD_COUNT":
      return {
        ...state,
        unread_message_count: action.payload, // Update the unread count with the payload
      };

    default:
      return state;
  }
};

export default authReducer;
