import React from "react";
import styled from "styled-components";
import colors from "../utils/constants/colors";
import NavBar from "../components/layout/NavBar";
import { connect } from "react-redux";
import OuterContainer from "../components/layout/OuterContainer";
import { Link } from "react-router-dom"; // Import Link for routing

const Container = styled.div`
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Heading = styled.h1`
  color: ${colors.primary};
  font-size: 1.4em;
  margin-bottom: 20px;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
  margin-bottom: 20px;
`;

const StepNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary};
  color: #fff;
  border-radius: 50%;
  width: 1.875em;
  height: 1.875em;
  font-size: 0.875em;
  flex-shrink: 0;
  flex-grow: 0;
`;

const StepDescription = styled.p`
  font-size: 1em;
`;

const HighlightedLink = styled(Link)`
  color: ${colors.secondary};
  text-decoration: underline;
  font-weight: bold;
  &:hover {
    color: ${colors.primary};
  }
`;

const HowItWorks = (props) => {
  return (
    <>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <Container>
          <Heading>
            Welcome to SwapThings, where you can swap, sell, or give away books!
          </Heading>
          <Step>
            <StepNumber>1</StepNumber>
            <StepDescription>
              Joining SwapThings is a breeze.{" "}
              <HighlightedLink to="/signup">Sign up</HighlightedLink> for your
              free account.
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>2</StepNumber>
            <StepDescription>
              Explore our diverse collection of used books. Discover items you'd
              love to swap for, buy, or even receive for free.
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>3</StepNumber>
            <StepDescription>
              List the books you would like to exchange, sell, or give away
              using the{" "}
              <HighlightedLink to="/swap">listing page</HighlightedLink>. You
              can choose to offer your book for a swap, sale, or giveaway—or a
              combination of these options!
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>4</StepNumber>
            <StepDescription>
              When you spot something you want, click "Swap" to exchange your
              book, "Buy" to purchase it, or "Receive" if it's a free giveaway.
              It's that easy!
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>5</StepNumber>
            <StepDescription>
              Chat directly with the other user to agree on the details of the
              transaction. Whether you're swapping, buying, or receiving a
              giveaway, make sure to discuss all the details.
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>6</StepNumber>
            <StepDescription>
              Once both parties are happy, it's time to finalize the exchange.
              Arrange for the items to be sent, picked up, or swapped, and enjoy
              your new book!
            </StepDescription>
          </Step>
          <p>
            Let's make the most of our books. Happy swapping, selling, or giving
            away!
          </p>
        </Container>
      </OuterContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(HowItWorks);
