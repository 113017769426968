import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

// Flex container to display checkboxes and labels on the same line
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const CheckboxLabel = styled.label`
  margin-right: 10px; /* Add some space between the label and the checkbox */
  font-size: 14px;
  color: #000;
`;

const CheckboxInput = styled.input`
  margin-right: 10px; /* Add space between the checkbox and other elements */
`;

const PriceInputContainer = styled.div`
  margin-bottom: 16px;
`;

const PriceInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  transition: border-color 0.2s ease-in-out;
  width: 100%; /* Make the input full width */
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: 5px;
  border-color: #000;
  border: 1px solid #dfdfdf;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  max-width: 400px;
  margin-right: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 8px;
  // font-weight: 600;
  color: #000;
  font-size: 14px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  transition: border-color 0.2s ease-in-out;
`;

const TextArea = styled.textarea`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  resize: none;
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
  overflow: scroll;

  & > * {
    flex-shrink: 0;
  }
`;

const ImageUploadButton = styled.label`
  display: inline-block;
  background-color: #00a87e;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007f5f;
  }

  > input[type="file"] {
    display: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 5px;
`;

const RemoveImageButton = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: none;
  cursor: pointer;
  color: #efefef;
  background-color: rgba(0, 0, 0, 0.7);
`;

const SubmitButton = styled.button`
  display: inline-block;
  background-color: #00a87e;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #007f5f;
  }
`;

const DropdownContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #666;
  font-size: 14px;
  background-color: white; /* add this line to set the background color to white */

  &::placeholder {
    // color: red;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #f0f0f0;
  }
`;

// Basic styled components or divs for layout - you'll replace or style these as needed
const FormSkeletonContainer = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
`;

const InputSkeleton = styled(Skeleton)`
  margin: 10px 0;
`;

const TextAreaSkeleton = styled(Skeleton)`
  margin: 10px 0;
  height: 60px;
`;

const SelectSkeleton = styled(Skeleton)`
  margin: 10px 0;
`;

const ButtonSkeleton = styled(Skeleton)`
  margin: 10px 0;
  height: 40px;
`;

const CancelButton = styled.button`
  display: inline-block;
  background-color: #ccc;
  color: #333;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  margin-left: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #999;
  }
`;

export const FormListing = (props) => {
  const {
    formData = {
      title: "",
      description: "",
      zipcode: "",
      images: [],
      categories: [],
      subcategories: [],
      itemconditions: [],
      is_available_for_swap: false, // New Field
      is_available_for_sale: false, // New Field
      is_available_for_giveaway: false, // New Field
      price: "", // New Field for optional price
    },
    imagePreviews = [],
    handleFormFieldChange = () => {},
    handleRemoveImage = () => {},
    handleSubmit = () => {},
    handleCategoryChange = () => {},
    handleSubcategoryChange = () => {},
    handleItemConditionChange = () => {},
    categories = [],
    subcategories = [],
    itemconditions = [],
    selectedCategory = "",
    selectedSubcategory = "",
    selectedItemCondition = "",
    textAreaRef = { current: null },
    cancelPath = null,
  } = props;

  const navigate = useNavigate();

  const handleCancel = () => {
    if (cancelPath) {
      navigate(cancelPath);
    }
  };

  console.log(1.1);

  return (
    <Form onSubmit={handleSubmit}>
      <InputContainer>
        <Label htmlFor="title">Title</Label>
        <Input
          type="text"
          id="title"
          value={formData.title}
          onChange={handleFormFieldChange}
        />
      </InputContainer>

      <InputContainer>
        <Label htmlFor="description">Description</Label>
        <TextArea
          id="description"
          ref={textAreaRef}
          value={formData.description}
          onChange={handleFormFieldChange}
          rows="3"
        />
      </InputContainer>

      <DropdownContainer>
        <Label htmlFor="category">Category</Label>
        <Select
          id="category"
          name="category"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="">Category</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Select>
      </DropdownContainer>

      <DropdownContainer>
        <Label htmlFor="subcategory">Subcategory</Label>
        <Select
          id="subcategory"
          name="subcategory"
          value={JSON.stringify(selectedSubcategory)}
          onChange={handleSubcategoryChange}
          disabled={!selectedCategory}
        >
          <option value="">Subcategory</option>
          {subcategories.map((subcategory) => (
            <option key={subcategory.id} value={JSON.stringify(subcategory)}>
              {subcategory.name}
            </option>
          ))}
        </Select>
      </DropdownContainer>

      <DropdownContainer>
        <Label htmlFor="itemconditions">Item Condition</Label>
        <Select
          id="itemconditions"
          name="itemconditions"
          value={selectedItemCondition}
          onChange={handleItemConditionChange}
        >
          <option value="">Condition</option>
          {itemconditions.map((itemcondition) => (
            <option key={itemcondition} value={itemcondition}>
              {itemcondition}
            </option>
          ))}
        </Select>
      </DropdownContainer>

      <InputContainer>
        <Label htmlFor="zipcode">Zip Code</Label>
        <Input
          type="text"
          id="zipcode"
          value={formData.zipcode}
          onChange={handleFormFieldChange}
        />
      </InputContainer>

      {/* Swap Checkbox */}
      <CheckboxContainer>
        <CheckboxInput
          type="checkbox"
          id="is_available_for_swap"
          checked={formData.is_available_for_swap}
          onChange={handleFormFieldChange}
        />
        <CheckboxLabel htmlFor="is_available_for_swap">
          Offer for Swap
        </CheckboxLabel>
      </CheckboxContainer>

      {/* Sale Checkbox */}
      <CheckboxContainer>
        <CheckboxInput
          type="checkbox"
          id="is_available_for_sale"
          checked={formData.is_available_for_sale}
          onChange={handleFormFieldChange}
        />
        <CheckboxLabel htmlFor="is_available_for_sale">
          Put Up for Sale
        </CheckboxLabel>
      </CheckboxContainer>

      {/* Price input shown only if available for sale */}
      {formData.is_available_for_sale && (
        <PriceInputContainer>
          <Label htmlFor="price">Price (if for sale)</Label>
          <PriceInput
            type="number" // Restrict input to numbers and decimals
            id="price"
            value={formData.price}
            onChange={handleFormFieldChange}
            step="0.01" // Allow decimals
            min="0" // Prevent negative values
            placeholder="Enter price"
          />
        </PriceInputContainer>
      )}

      {/* Giveaway Checkbox */}
      <CheckboxContainer>
        <CheckboxInput
          type="checkbox"
          id="is_available_for_giveaway"
          checked={formData.is_available_for_giveaway}
          onChange={handleFormFieldChange}
        />
        <CheckboxLabel htmlFor="is_available_for_giveaway">
          Give Away for Free
        </CheckboxLabel>
      </CheckboxContainer>

      <ImageUploadButton>
        Add Photo
        <input
          type="file"
          id="images"
          multiple
          style={{ display: "none" }}
          onChange={handleFormFieldChange}
        />
      </ImageUploadButton>

      <ImagePreviewContainer>
        {imagePreviews.map((src, index) => (
          <ImageContainer key={index} src={src}>
            <RemoveImageButton onClick={() => handleRemoveImage(index)}>
              x
            </RemoveImageButton>
          </ImageContainer>
        ))}
      </ImagePreviewContainer>

      <div>
        <SubmitButton type="submit">Submit</SubmitButton>
        {cancelPath && (
          <CancelButton type="button" onClick={handleCancel}>
            Cancel
          </CancelButton>
        )}
      </div>
    </Form>
  );
};

export const FormListingSkeleton = () => {
  return (
    <FormSkeletonContainer>
      {/* Simulate Title Input */}
      <InputSkeleton variant="text" width="70%" height={20} />

      {/* Simulate Description TextArea */}
      <TextAreaSkeleton variant="text" width="100%" />

      {/* Simulate Category Dropdown */}
      <SelectSkeleton variant="text" width="50%" height={20} />

      {/* Simulate Subcategory Dropdown */}
      <SelectSkeleton variant="text" width="50%" height={20} />

      {/* Simulate Item Condition Dropdown */}
      <SelectSkeleton variant="text" width="50%" height={20} />

      {/* Simulate ZIP Code Input */}
      <InputSkeleton variant="text" width="30%" height={20} />

      {/* Simulate Image Upload Button */}
      <ButtonSkeleton variant="rect" width="30%" />

      {/* Submit Button Skeleton - Optional, you might not need to simulate this */}
      <ButtonSkeleton variant="rect" width="20%" />
    </FormSkeletonContainer>
  );
};
