import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import OuterContainer from "../components/layout/OuterContainer";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import { uploadToS3 } from "../utils/helpers/s3";
import NavBar from "../components/layout/NavBar";
import { Listing } from "../components/Listing";
import { FormListing, FormListingSkeleton } from "../components/ListingForm";
import placeholder from "../assets/placeholder.jpeg";
import Loader from "../components/Loading";
import { size } from "../utils/constants/css";
import Modal from "../components/Modal";

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
  background-color: #ffd2d2;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

const PreviewContainer = styled.div`
  width: 250px;
  min-height: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${size.tablet}px) {
    display: none;
  }
`;

function validateFields(formData) {
  const errors = {};
  if (!formData.title) {
    errors.title = "Title is required";
  }
  if (!formData.description) {
    errors.description = "Description is required";
  }
  if (!formData.zipcode) {
    errors.zipcode = "Location is required";
  }
  if (!formData.images || formData.images.length < 1) {
    errors.images = "Please upload at least one image";
  }
  // Additional validation if listing is available for sale
  if (formData.is_available_for_sale && !formData.price) {
    errors.price = "Price is required if listing is available for sale";
  }
  return errors;
}

const Trade = (props) => {
  // State variables to hold form data
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    zipcode: "",
    images: [],
    category_id: "",
    subcategory_id: "",
    item_condition_id: "",
    is_available_for_swap: true, // New field for swap option
    is_available_for_sale: false, // New field for sale option
    is_available_for_giveaway: false, // New field for giveaway option
    price: "", // New field for price (only required if selling)
  });

  const [imagePreviews, setImagePreviews] = useState([]);
  const [presignedUrls, setPresignedUrls] = useState([]);
  const [listingId, setListingId] = useState();
  const [catData, setcatData] = useState({});
  const [itemConditionData, setItemConditionData] = useState({});
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemConditions, setItemConditions] = useState([]);

  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedItemCondition, setSelectedItemCondition] = useState("");
  const textAreaRef = useRef(null);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function adjustTextAreaHeight() {
    const textArea = textAreaRef.current;
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  }

  const handleFormFieldChange = (event) => {
    const fieldName = event.target.id;
    // let fieldValue = event.target.value;
    let fieldValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (event.target.type === "file") {
      const newImages = Array.from(event.target.files);
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...newImages],
      }));

      const previews = newImages.map((file) => URL.createObjectURL(file));
      setImagePreviews((prevImagePreviews) => [
        ...prevImagePreviews,
        ...previews,
      ]);
    } else {
      setFormData({ ...formData, [fieldName]: fieldValue });
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = [...formData.images];
    newImages.splice(index, 1);
    setFormData({ ...formData, images: newImages });

    const newPreviews = [...imagePreviews];
    newPreviews.splice(index, 1);
    setImagePreviews(newPreviews);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setIsLoading(true);
  //   const errors = validateFields(formData);
  //   const imagePaths = formData.images.map((image) => image.name);
  //   const formDataWithImages = {
  //     ...formData,
  //     images: imagePaths,
  //   };
  //   const formDataJSON = JSON.stringify(formDataWithImages);
  //   if (Object.keys(errors).length === 0) {
  //     const endpoint = API_CONSTANT_MAP.create_listing;
  //     try {
  //       const response = await request("POST", endpoint, formDataJSON);
  //       console.log(response);
  //       if (response.data.success) {
  //         setListingId(response.data.id);
  //         setPresignedUrls(response.data.presigned_urls);
  //       } else {
  //         // setErrorMsg(response.data.error.message);
  //       }

  //       // setIsLoading(false);
  //     } catch (err) {
  //       setIsLoading(false);
  //       setErrorModal(true);
  //       setErrorMessage(err.error.message);
  //     }
  //   } else {
  //     setIsLoading(false);
  //     // Display error messages to user
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const errors = validateFields(formData);
    if (Object.keys(errors).length === 0) {
      const formDataWithImages = {
        ...formData,
        images: formData.images.map((image) => image.name), // Assuming you handle images separately
      };
      const formDataJSON = JSON.stringify(formDataWithImages);
      const endpoint = API_CONSTANT_MAP.create_listing;
      try {
        const response = await request("POST", endpoint, formDataJSON);
        if (response.data.success) {
          setListingId(response.data.id);
          setPresignedUrls(response.data.presigned_urls);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setErrorModal(true);
        setErrorMessage(err.error.message);
      }
    } else {
      setIsLoading(false);
      // Handle errors (e.g., show validation messages)
    }
  };

  useEffect(() => {
    const textArea = textAreaRef.current;
    textArea.addEventListener("input", adjustTextAreaHeight);
    return () => {
      textArea.removeEventListener("input", adjustTextAreaHeight);
    };
  }, []);

  useEffect(() => {
    const uploadImagesToS3 = async () => {
      // setIsLoading(true);
      if (presignedUrls.length > 0 && listingId) {
        try {
          const results = await Promise.allSettled(
            presignedUrls.map((url, index) =>
              uploadToS3(url, formData.images[index])
            )
          );
          const rejects = results.filter((x) => x.status !== "fulfilled");
          if (rejects.length === 0) {
            console.log("All fulfilled");
            const image_paths = presignedUrls.map(
              (e) => e.url + e.fields["key"]
            );
            const endpoint = API_CONSTANT_MAP.create_listing_image;
            const response = await request("POST", endpoint, {
              listing_id: listingId,
              image_urls: image_paths,
            });

            setIsLoading(false);
            navigate("/Listings");
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      }
    };
    uploadImagesToS3();
  }, [presignedUrls]);

  useEffect(() => {
    setIsLoading(true);

    const categoriesPromise = request("POST", API_CONSTANT_MAP.get_categories);
    const itemConditionsPromise = request(
      "POST",
      API_CONSTANT_MAP.get_item_conditions
    );

    Promise.all([categoriesPromise, itemConditionsPromise])
      .then((results) => {
        const [categoriesResult, itemConditionsResult] = results;

        if (categoriesResult.data.success) {
          setcatData(categoriesResult.data.categories);
          const categories = Object.keys(categoriesResult.data.categories);
          setCategories(categories);
        }

        if (itemConditionsResult.data.success) {
          setItemConditionData(itemConditionsResult.data.item_conditions);
          setItemConditions(
            Object.keys(itemConditionsResult.data.item_conditions)
          );
        }
      })
      .catch(() => {
        // Handle errors as necessary
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(value);
    setSelectedSubcategory("");
    if (!categories.includes(value)) {
      setSubcategories([]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        category_id: null,
      }));
    } else {
      const categoryData = catData[value];
      setSubcategories(categoryData["subcategories"]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        category_id: categoryData["id"],
      }));
    }
  };

  const handleItemConditionChange = (event) => {
    const value = event.target.value;
    setSelectedItemCondition(value);

    const itemCondition = itemConditionData[value];
    setFormData((prevFormData) => ({
      ...prevFormData,
      item_condition_id: itemCondition["id"],
    }));
  };

  const handleSubcategoryChange = (event) => {
    const parsed = JSON.parse(event.target.value);

    setSelectedSubcategory(parsed);
    setFormData((prevFormData) => ({
      ...prevFormData,
      subcategory_id: parsed.id,
    }));
  };

  const handleErrorModal = (e) => {
    setErrorModal(!errorModal);
  };

  // Function to handle form submission
  return (
    <>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <FormListing
          formData={formData}
          imagePreviews={imagePreviews}
          handleFormFieldChange={handleFormFieldChange}
          handleRemoveImage={handleRemoveImage}
          handleSubmit={handleSubmit}
          handleCategoryChange={handleCategoryChange}
          handleSubcategoryChange={handleSubcategoryChange}
          handleItemConditionChange={handleItemConditionChange}
          categories={categories}
          subcategories={subcategories}
          itemconditions={itemConditions}
          selectedCategory={selectedCategory}
          selectedSubcategory={selectedSubcategory}
          selectedItemCondition={selectedItemCondition}
          textAreaRef={textAreaRef}
        />

        <PreviewContainer>
          <Listing
            id={1000000000}
            title={formData.title}
            imageUrl={imagePreviews.length ? imagePreviews[0] : placeholder}
            description={formData.description}
          />
        </PreviewContainer>
      </OuterContainer>
      <Modal isOpen={errorModal} onClose={handleErrorModal}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Modal>
      <Loader animating={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(Trade);
