export const signup = (response) => {
  return {
    type: response.type,
    payload: response.payload,
  };
};

export const signin = (response) => {
  return {
    type: response.type,
    payload: response.payload,
  };
};

export const signout = () => {
  return {
    type: "SIGNOUT",
    payload: undefined,
  };
};

export const zipcode = (data) => {
  return {
    type: "ZIPCODE",
    payload: data.payload,
  };
};

export const updateUnreadCount = (data) => {
  return {
    type: "UPDATE_UNREAD_COUNT",
    payload: data.payload,
  };
};

export const location = (data) => {
  return {
    type: "LOCATION",
    payload: data.payload,
  };
};

export const search = (data) => {
  return {
    type: "SEARCH",
    payload: data.payload,
  };
};

export const distance = (data) => {
  return {
    type: "DISTANCE",
    payload: data.payload,
  };
};

// Action to set the entire list of categories and their subcategories
export const categories = (data) => {
  return {
    type: "SET_CATEGORIES",
    payload: data.payload,
  };
};

export const itemConditions = (data) => {
  return {
    type: "ITEM_CONDITIONS",
    payload: data.payload,
  };
};

export const sortBy = (data) => {
  return {
    type: "SORT",
    payload: data.payload,
  };
};

export const zipcodeResults = (data) => {
  return {
    type: "ZIPCODE_RESULTS",
    payload: data.payload,
  };
};

export const zipcodeDistChanged = (data) => {
  return {
    type: "ZIPCODE_DISTANCE_CHANGED",
    payload: data.payload,
  };
};

// Optional: Action to select a specific category
export const selectCategory = (categoryId) => {
  return {
    type: "SELECT_CATEGORY",
    payload: categoryId,
  };
};

// Optional: Action to select a specific subcategory within a category
export const selectSubcategory = (categoryId, subcategoryId) => {
  return {
    type: "SELECT_SUBCATEGORY",
    payload: { categoryId, subcategoryId },
  };
};
