import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { get_token } from "../utils/helpers/api";
import { request } from "../services/networking/request";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import styled from "styled-components";
import { connect } from "react-redux";
import NavBar from "../components/layout/NavBar";
import OuterContainer from "../components/layout/OuterContainer";
import Modal from "../components/Modal";
import Button from "../components/common/Button";
import { Listing } from "../components/Listing";
import RoundedImage from "../components/RoundedImage";
import unknown_gw from "../assets/unknown_gw.png";
import { size } from "../utils/constants/css";
import Loader from "../components/Loading";
import Skeleton from "react-loading-skeleton";

const ModalTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  display: flex;
  justify-content: center;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const ChatContainer = styled.div`
  display: ${(props) => (props.initialLoading ? "block" : "flex")};
  flex-direction: column;
  width: 60%;
  height: 500px;
  overflow: scroll;
  border: 1px solid #c2c2c2;
  border-radius: 1rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 10px;
    height: 600px;
  }
`;

const SwapContainer = styled.div`
  display: ${(props) => (props.initialLoading ? "block" : "flex")};
  flex-direction: column;
  width: 30%;
  height: 500px;
  overflow-y: scroll;
  border: 1px solid #c2c2c2;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    height: 200px;
    padding: 5px;
  }
`;

// Message list
const SwapList = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 20px;
  flex-wrap: wrap;
  row-gap: 40px;
  background-color: #fff;

  @media screen and (max-width: 768px) {
    flex: 1;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
    flex-wrap: nowrap;
    column-gap: 20px;
    padding: 0px;
    row-gap: 0px;
    justify-content: flex-start;
  }
`;
// Message list
const MessageList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

// Message item
const MessageItem = styled.li`
  margin: 10px;
  padding: 10px;
  max-width: 50%;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: ${({ isCurrentUser }) =>
    isCurrentUser ? "#DCF8C6" : "#EFEFEF"};
  align-self: ${({ isCurrentUser }) =>
    isCurrentUser ? "flex-end" : "flex-start"};
`;

// Message content
const MessageContent = styled.span`
  font-size: 16px;
  color: #333;
`;

const ProposeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  background-color: #fff;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ProposeContainersSmallerScreen = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0px;
    background-color: #fff;
    align-items: center;
    justify-content: flex-start;
  }
`;
// Input form
const InputForm = styled.form`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const ChatContext = styled.div`
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #c2c2c2;
  justify-content: space-between;
`;

// Input field
const InputField = styled.input`
  flex: 1;
  border: none;
  border-radius: 20px;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #c2c2c2;
`;

// Send button
const SendButton = styled.button`
  border: none;
  background-color: #00a87e;
  color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #00a87e;
  }
`;

const NewProposalButton = styled.button`
  background-color: #00a87e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  bottom: 20px;
  right: 20px;

  &:hover {
    background-color: #00a87e;
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
    border-radius: 1rem;
  }
`;

const SwapImageContainer = styled.div`
  width: 200px;
  height: 200px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }

  & > img {
    width: 100%;
    height: 150px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    object-fit: cover;
  }
`;

const SwapDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

  & > h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #121212;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & > p {
    font-size: 14px;
    margin-bottom: 8px;
    color: #666666;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & > span {
    font-size: 16px;
    font-weight: 600;
    color: #008489;
  }
`;

const UserSwapsList = styled.div`
  overflow-y: scroll;
  height: 70%;
  margin: 10px 0px;
`;

const ListingBigScreen = styled.div`
  display: block;
  @media only screen and (max-width: ${size.mobileM}px) {
    display: none;
  }
`;

const ListingSmallScreen = styled.div`
  display: none;
  @media only screen and (max-width: ${size.mobileM}px) {
    display: block;
  }
`;

const OtherUserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > p {
    margin: 0px 10px;
    font-size: 14px;
    font-weight: 600;
  }
`;

function Swap({ swap }) {
  return (
    <SwapImageContainer>
      <img
        src={swap.listing.image}
        alt={swap.listing.title}
        key={swap.listing.image}
      />
      <SwapDiv>
        <h3>{swap.listing.title}</h3>
      </SwapDiv>
    </SwapImageContainer>
  );
}

const ChatMessenger = (props) => {
  const [messages, setMessages] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [buyerListings, setBuyerListings] = useState([]);
  const [primaryListing, setPrimaryListing] = useState({});
  const [otherUser, setOtherUser] = useState({});
  const [swapSelect, setSwapSelect] = useState({});
  const [swapOpen, setSwapOpen] = useState(false);
  const [checkedState, setCheckedState] = useState();
  const token = get_token();
  const webSocket = useRef();
  const { id } = useParams();
  const current_user_id = props.state.user.user_id;
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const messageListRef = useRef(null);
  // console.log(messages);

  useEffect(() => {
    // Scroll to the bottom of the message list when messages change or new messages arrive
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    let isMounted = true; // flag to check if the component is mounted
    setInitialLoading(true); // Assuming the correct spelling is 'setInitialLoading'

    const fetchData = async () => {
      try {
        const messages_endpoint = API_CONSTANT_MAP.get_messages;
        const swaps_endpoint = API_CONSTANT_MAP.get_conversation_swaps;
        const bl_endpoint = API_CONSTANT_MAP.get_user_listings;
        const primary_listing_endpoint =
          API_CONSTANT_MAP.get_listing_by_conv_id;

        const commonData = { conversation_id: id };
        const commonHeaders = { Authorization: `Bearer ${token}` };

        const [
          messagesResponse,
          swapsResponse,
          buyerListingsResponse,
          primaryListingResponse,
        ] = await Promise.all([
          request("POST", messages_endpoint, commonData, commonHeaders),
          request("POST", swaps_endpoint, commonData, commonHeaders),
          request("POST", bl_endpoint, commonData, commonHeaders),
          request("POST", primary_listing_endpoint, commonData, commonHeaders),
        ]);

        if (isMounted) {
          setMessages(messagesResponse.data.messages);
          setSwaps(swapsResponse.data.swaps);
          setBuyerListings(buyerListingsResponse.data.buyer_listings);
          setPrimaryListing(primaryListingResponse.data.listing);
          setOtherUser(primaryListingResponse.data.other_user);
          setInitialLoading(false);
        }
      } catch (error) {
        console.error(error);
        if (isMounted) {
          setInitialLoading(false);
        }
      }
    };

    fetchData();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, []); // Dependency array is empty, so this effect runs only on mount and unmount

  useEffect(() => {
    var arr = new Array(buyerListings.length).fill(false);
    setCheckedState(arr);
  }, [buyerListings]);

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl = `wss://ver0h8nfcc.execute-api.us-west-2.amazonaws.com/prod/?conversation_id=${id}&authorization=${token}`;
      webSocket.current = new WebSocket(wsUrl);

      webSocket.current.onopen = () => {
        console.log("WebSocket connection opened");
        webSocket.current.send(JSON.stringify({ type: "CONNECT" }));
      };

      webSocket.current.onmessage = (event) => {
        console.log("WebSocket connection received message");
        const message = JSON.parse(event.data);
        if (message.hasOwnProperty("conversation_id")) {
          setMessages((prevMessages) => [...prevMessages, message]);
        }
      };

      webSocket.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        // Optionally handle WebSocket errors, e.g., by showing an error message to the user
      };

      webSocket.current.onclose = (event) => {
        console.log("WebSocket closed:", event);
        webSocket.current = null;
        if (!event.wasClean) {
          // Reconnect in case of unclean close
          setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
        }
      };
    };

    connectWebSocket();

    return () => {
      // Ensure the WebSocket is closed cleanly when the component unmounts
      if (webSocket.current) {
        webSocket.current.send(JSON.stringify({ type: "DISCONNECT" }));
        webSocket.current.close();
      }
    };
  }, [id, token]); // Reconnect if `id` or `token` changes

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleNewMessageSubmit = (event) => {
    event.preventDefault();

    webSocket.current.send(
      JSON.stringify({
        type: "MESSAGE",
        conversation_id: id,
        token,
        content: newMessage,
      })
    );
    setNewMessage("");
  };

  const handleNewProposal = (e) => {
    e.preventDefault();
    setSwapOpen(!swapOpen);
  };

  const handleProposalSelection = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    const selectedItems = buyerListings.filter(
      (item, index) => updatedCheckedState[index]
    );
    const selectedItemIds = selectedItems.map((item) => parseInt(item["id"]));
    setSwapSelect(selectedItemIds);
  };

  const submitProposal = () => {
    setIsLoading(true);
    const endpoint = API_CONSTANT_MAP.update_conversation_swaps;
    const result = request("POST", endpoint, {
      conversation_id: id,
      swaps: swapSelect,
    });
    result
      .then((result) => {
        if (result.data.success) {
          setSwaps(result.data.swaps);
          setSwapOpen(!swapOpen);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setSwapOpen(!swapOpen);
      });
  };

  const openListing = (id) => {
    navigate(`/listing/details/${id}`);
  };

  const handleView = (e, listing_id) => {
    e.preventDefault();
    navigate(`/listing/details/${listing_id}`);
  };

  useEffect(() => {
    const endpoint = API_CONSTANT_MAP.update_conversation_read;
    const result = request("POST", endpoint, {
      conversation_id: id,
    });

    result
      .then((response) => {
        if (response.data.success) {
          //  setIsLoaded(true); // Set the loaded state to true once the data is fetched
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          //  setErrorMsg(error.response.data.error.message);
        }
      });
  }, []);

  return (
    <>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <Main>
          {initialLoading && <ChatSkeleton initialLoading={initialLoading} />}
          {!initialLoading && (
            <>
              <ChatContainer initialLoading={initialLoading}>
                <ChatContext>
                  <OtherUserContainer>
                    <RoundedImage
                      imageHeight={50}
                      imageWidth={50}
                      // borderRadiusPct={5}
                      image={otherUser?.avatar ? otherUser.avatar : unknown_gw}
                    />
                    <p>
                      {otherUser.first_name} {otherUser.last_name}
                    </p>
                  </OtherUserContainer>

                  <Listing
                    id={primaryListing.id}
                    imageUrl={primaryListing.image_url}
                    listingActions={true}
                    onView={handleView}
                    onlyImage={true}
                    width={50}
                    height={50}
                  />
                </ChatContext>

                <MessageList ref={messageListRef}>
                  {messages.map((message) => (
                    <MessageItem
                      key={message.id}
                      isCurrentUser={message.sender_id === current_user_id}
                    >
                      <MessageContent>{message.content}</MessageContent>
                    </MessageItem>
                  ))}
                </MessageList>
                <InputForm onSubmit={handleNewMessageSubmit}>
                  <InputField
                    type="text"
                    value={newMessage}
                    onChange={handleNewMessageChange}
                    placeholder="Type a message..."
                  />
                  <SendButton type="submit">Send</SendButton>
                </InputForm>
              </ChatContainer>

              <SwapContainer initialLoading={initialLoading}>
                <SwapList>
                  <ProposeContainersSmallerScreen>
                    <NewProposalButton onClick={handleNewProposal}>
                      Propose New Swap
                    </NewProposalButton>
                  </ProposeContainersSmallerScreen>
                  {swaps.length > 0 &&
                    swaps.map((swap) => (
                      <div>
                        <ListingBigScreen>
                          <Listing
                            id={swap.listing.id}
                            title={swap.listing.title}
                            imageUrl={swap.listing.image}
                            description={swap.listing.description}
                            onClick={openListing}
                            width={150}
                            height={150}
                          />
                        </ListingBigScreen>
                        <ListingSmallScreen>
                          <Listing
                            id={swap.listing.id}
                            title={swap.listing.title}
                            imageUrl={swap.listing.image}
                            description={swap.listing.description}
                            onClick={openListing}
                            width={100}
                            height={100}
                          />
                        </ListingSmallScreen>
                      </div>
                    ))}
                </SwapList>
                <ProposeContainer>
                  <NewProposalButton onClick={handleNewProposal}>
                    Propose New Swap
                  </NewProposalButton>
                </ProposeContainer>
              </SwapContainer>
            </>
          )}
        </Main>

        <Modal isOpen={swapOpen} onClose={handleNewProposal}>
          <ModalTitle>Select the items you would like to swap</ModalTitle>
          <Button type="submit" onClick={submitProposal}>
            Submit Proposal
          </Button>
          <UserSwapsList>
            {Object.keys(buyerListings).length !== 0 &&
              buyerListings.map((item, index) => (
                <div
                  key={`multiselect-checkbox-${item.id}`}
                  style={{
                    border: "1px solid #c0c0c0",
                    padding: "5px",
                    margin: "10px 0px",
                  }}
                >
                  <input
                    key={`multiselect-input-${item.id}`}
                    type="checkbox"
                    value={item.id || ""}
                    checked={checkedState[index]}
                    onChange={() => handleProposalSelection(index)}
                  />
                  <span style={{ marginLeft: 5 }}>{item.title}</span>
                </div>
              ))}
          </UserSwapsList>
        </Modal>
      </OuterContainer>
      <Loader animating={isLoading} />
    </>
  );
};

const ChatSkeleton = (initialLoading) => {
  return (
    <>
      <ChatContainer initialLoading={initialLoading}>
        <Skeleton width={"100%"} height={"100%"} />
      </ChatContainer>
      <SwapContainer initialLoading={initialLoading}>
        <Skeleton width={"100%"} height={"100%"} />
      </SwapContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(ChatMessenger);
